import React, { useEffect, useRef } from 'react';
import 'owl.carousel'; // Import Owl Carousel
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
import { motion } from "framer-motion";

const Section4 = () => {
    const [t, i18n] = useTranslation("global");

    const owlCarouselRef = useRef<HTMLDivElement>(null);

    const dir = Cookies.get('direction') || 'ltr';

    const rtl = (dir == 'rtl');

    const fadeInAnimationVariants = {
        initial: {
            opacity: 0,
            // y: 200
            x:200
        },
        animate: {
            opacity: 1,
            // y: 0,
            x:0,
            transition: { duration: 1, delay: 0.2 }
        }
    }

    useEffect(() => {
        let initialized = false;

        const initializeCarousel = () => {
            $(owlCarouselRef.current!).owlCarousel({
                margin: 30,
                loop: true,
                autoplay: true,
                autoplayTimeout: initialized ? 3000 : 0, // Set initial autoplayTimeout to 0
                smartSpeed: 3000,
                touchDrag: false,
                mouseDrag: false,
                rtl: rtl,
                nav: false,
                dots: false,
                items: 6,
                responsive: {
                    0: {
                        items: 2
                    },
                    600: {
                        items: 2
                    },
                    1000: {
                        items: 5
                    }
                }
            });

            // Set initialized to true after first initialization
            initialized = true;

            // Update autoplayTimeout after the first rotation
            $(owlCarouselRef.current!).on('changed.owl.carousel', function(event) {
                if (!initialized) return;
                $(owlCarouselRef.current!).trigger('stop.owl.autoplay');
                $(owlCarouselRef.current!).trigger('play.owl.autoplay', [3000]);
            });
        };

        initializeCarousel();

        // Cleanup on unmount
        return () => {
            $(owlCarouselRef.current!).trigger('destroy.owl.carousel');
        };
    }, [rtl]);
    
    return (
        <motion.div variants={fadeInAnimationVariants} initial="initial" whileInView={fadeInAnimationVariants.animate} viewport={{once:true}} className="container-fluid bg-white py-5">
            <div className="row container-xl mx-auto section4">
                <div className="col-xl-2 col-md-2 mb-sm-3 text-center text-md-start mb-4 mb-md-0 left d-md-flex align-items-center">
                    <h3 className="fs-5 m-0">{t("home.section4.title")}</h3>
                </div>
                <div ref={owlCarouselRef} className="owl-one owl-carousel owl-theme col-xl-10 col-md-10">
                    <div className="item"><img src="https://demo.cloudgoup.com/wp-content/uploads/2024/04/1.png" /></div>
                    <div className="item"><img src="https://demo.cloudgoup.com/wp-content/uploads/2024/04/9.svg" /></div>
                    <div className="item"><img src="https://demo.cloudgoup.com/wp-content/uploads/2024/04/2.png" /></div>
                    <div className="item"><img src="https://demo.cloudgoup.com/wp-content/uploads/2024/04/3.png" /></div>
                    <div className="item"><img src="https://demo.cloudgoup.com/wp-content/uploads/2024/04/7.png" /></div>
                    <div className="item"><img src="https://demo.cloudgoup.com/wp-content/uploads/2024/04/11-1.png" /></div>
                    <div className="item"><img src="https://demo.cloudgoup.com/wp-content/uploads/2024/04/10.png" /></div>
                    <div className="item"><img src="https://demo.cloudgoup.com/wp-content/uploads/2024/04/12-1.png" /></div>
                    <div className="item"><img src="https://demo.cloudgoup.com/wp-content/uploads/2024/04/8-1.png" /></div>
                    <div className="item"><img src="https://demo.cloudgoup.com/wp-content/uploads/2024/04/6.png" /></div>
                    <div className="item"><img src="https://demo.cloudgoup.com/wp-content/uploads/2024/04/5-1.png" /></div>
                    <div className="item"><img src="https://demo.cloudgoup.com/wp-content/uploads/2024/04/4-2.png" /></div>
                </div>
            </div>
            <style>{`
                .section4 .left h3{
                    line-height: 1em;
                    color: #272657;
                    font-weight: 600;
                }
            `}</style>
        </motion.div>
    )
}

export default Section4