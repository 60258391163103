import React from 'react'

const Section6 = () => {
  return (
        <div className="container-fluid bg-white py-5 section6">
            <div className="row container-xl mx-auto text-center">
                <h3 className="m-0">Looking for Digital solutions?<br></br><a href="#" className="fs-5 ms-2">Contact us.</a></h3>
            </div>
            <style>{`
                .section6 h3{
                    line-height: 1em;
                    letter-spacing: -0.045em;
                    color: #272657;
                    font-weight: 600;
                }
                .section6 a{
                    color: #64607d !important;
                    font-weight: 500;
                }
                .section6 a:hover{
                    color: #403d4e !important;
                }
            `}</style>
        </div>
    )
}

export default Section6