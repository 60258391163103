import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import 'owl.carousel';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import Cookies from 'js-cookie';

// import 'bootstrap-select/dist/css/bootstrap-select.min.css';


import global_en from "./translations/en/global.json";
import global_ar from "./translations/ar/global.json";
import i18next from 'i18next';
import { I18nextProvider } from 'react-i18next';

const savedLanguage = Cookies.get('language') || 'en';

const savedDirection = Cookies.get('direction') || 'ltr';

document.body.dir = savedDirection;

i18next.init({
  interpolation: {escapeValue: false},
  lng: savedLanguage,
  resources: {
    en: {
      global: global_en
    },
    ar: {
      global: global_ar
    }
  }
})

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18next}>
      <App />
    </I18nextProvider>
  </React.StrictMode>
);

reportWebVitals();
