import React, { useRef } from 'react';
import { FaAngleRight, FaCheck } from "react-icons/fa6";
import HoverCard from "@darenft/react-3d-hover-card";
import "@darenft/react-3d-hover-card/dist/style.css";
import { motion } from "framer-motion";

const Section7: React.FC = () => {
    const ref = useRef<HTMLDivElement>(null);
    const fadeInAnimationVariants = {
      initial: {
          opacity: 0,
          // y: 200,
          x:-200
      },
      animate: {
          opacity: 1,
          // y: 0,
          x:0,
          transition: { duration: 1, delay: 0.2 }
      }
  }
  return (
    <motion.div variants={fadeInAnimationVariants} initial="initial" whileInView={fadeInAnimationVariants.animate} viewport={{once:true}} className="container-fluid py-5">
        <div className="container-xl mx-auto section7">
            <div className="row1">
                <h3 className="">WHITE LABELS APPS</h3>
            </div>
            <div className="row align-items-center">
                <div className="col-xl-6 col-10 d-flex flex-column gap-4">
                    <div className="title">
                        <span>Mobile</span><br></br>
                        <span>Application</span><br></br>
                        <span>for E-grocery</span>
                    </div>
                    <div><p className="m-0">DeliverEase: Your All-in-One Delivery App Solution</p></div>
                    <div className="d-flex flex-column gap-1">
                        <div className="d-flex align-items-center"><span className="fs-5 s1 rounded-5 p-1"><FaCheck /></span><span className="fs-5 ms-3 s2">Easy to use</span></div>
                        <div className="d-flex align-items-center"><span className="fs-5 s1 rounded-5 p-1"><FaCheck /></span><span className="fs-5 ms-3 s2">Multi Language</span></div>
                        <div className="d-flex align-items-center"><span className="fs-5 s1 rounded-5 p-1"><FaCheck /></span><span className="fs-5 ms-3 s2">IOS & Andriod</span></div>
                    </div>
                    <div><a type="button" href="#" className="rounded-5">Get Started <FaAngleRight /></a></div>
                </div>
                <div className="col-xl-6 col-10">
                    <img src="https://demo.cloudgoup.com/wp-content/uploads/2024/04/4-1-e1714380650825.png" alt="laptop" className="img-fluid" />
                </div>
            </div>
        </div>
        <style>{`
              .section7 h3{
                width: fit-content;
                font-size: 15px;
                text-transform: uppercase;
                padding: 12px 20px 12px 20px;
                background-color: #F8FAE7;
                border-radius: 99px 99px 99px 99px;
                color: #272657;
                font-weight: 600;
                box-shadow: 0px 2px 1px 0px rgba(75.00000000000001, 99.00000000000007, 147, 0.14901960784313725);
              }
              
              .section7 .title span{
                line-height: 1em;
                letter-spacing: -0.045em;
                font-size: 70px;
                color: #272657;
                font-weight: 600;
              }
              .section7 p{
                color: #64607d;
                font-size: 20px;
                font-weight: 500;
                line-height: 30px;
              }
              .section7 .s1{
                background-color: #FBDCEB;
                display: inline-flex;
                color: rgb(240, 112, 174) !important;
              }
              .section7 .s2{
                color: #272657;
                font-weight: 600;
              }
              .section7 a{
                font-family: "Wix Madefor Text", Sans-serif;
                font-size: 15px;
                font-weight: 500;
                line-height: 1em;
                padding: 18px 30px 18px 30px;
                margin: 0;
                background: #1d2840;
                text-decoration: none;
                color: #ffffff !important;
                box-shadow: none;
                transition: all 0.65s cubic-bezier(0.23, 1, 0.32, 1);
              }
              .section7 a:hover{
                background: #141c2c;
                transform: scale(1.05);
              }
        `}</style>
    </motion.div>
  )
}

export default Section7