import Cookies from 'js-cookie';
import React, { useEffect, useRef } from 'react'
import { motion } from "framer-motion";

const Section9 = () => {
    const dir = Cookies.get('direction') || 'ltr';

    const rtl = (dir == 'rtl');

    const owlCarouselRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        // Initialize Owl Carousel
        $(owlCarouselRef.current!).owlCarousel({
            loop: true,
            autoplay: true,
            autoplayTimeout: 2000, 
            margin: 30,
            nav: true,
            dotsEach: false,
            stagePadding: 0,
            // autoplaySpeed: 3000,
            // slideTransition: 'linear',
            // fluidSpeed: false,
            // navText: ['&#8592;', '&#8594;'],
            // center: true,
            autoplayHoverPause: true,
            rtl: rtl,
            dots:true,
            items: 20,
            responsive:{
                0:{
                    items:1
                },
                600:{
                    items:2
                },
                800:{
                    items:3
                },
                1000:{
                    items:3
                }
            }
        /* Owl Carousel options */
        });

        // Cleanup on unmount
        return () => {
        $(owlCarouselRef.current!).trigger('destroy.owl.carousel');
        };
    }, [rtl]);

    const fadeInAnimationVariants = {
        initial: {
            opacity: 0,
            // y: 200
            x:200
        },
        animate: {
            opacity: 1,
            // y: 0,
            x:0,
            transition: { duration: 1, delay: 0.2 }
        }
    }

  return (
    <motion.div variants={fadeInAnimationVariants} initial="initial" whileInView={fadeInAnimationVariants.animate} viewport={{once:true}} className="container-fluid py-5">
        <div className="container-xl mx-auto row3">
            <div className="row1">
                <h3 className="">Solutions</h3>
            </div>
            <div className="row row2 my-5">
                <div className="col-md-6 col-sm-8 mb-4 mb-sm-0"><span className="">App solutions For all concepts</span></div>
                <div className="col-md-6 col-sm-8 d-flex align-items-center justify-content-end"><p className='m-0'>The Unified Mobile App Solution for Diverse Delivery Services. From groceries to parcels, meals to essentials, our streamlined platform optimizes logistics, ensuring seamless operations and exceptional customer experiences across all delivery types.</p></div>
            </div>
        </div>
        <div className="row3 d-flex justify-content-center">
            <div ref={owlCarouselRef} className="owl-carousel owl-theme col-xl-10 col-md-10">
                <div className="item"><img src="https://demo.cloudgoup.com/wp-content/uploads/2024/05/2-5.png" /></div>
                <div className="item"><img src="https://demo.cloudgoup.com/wp-content/uploads/2024/05/3-4.png" /></div>
                <div className="item"><img src="https://demo.cloudgoup.com/wp-content/uploads/2024/05/1-3.png" /></div>
                <div className="item"><img src="https://demo.cloudgoup.com/wp-content/uploads/2024/05/6-3.png" /></div>
                <div className="item"><img src="https://demo.cloudgoup.com/wp-content/uploads/2024/05/5-2.png" /></div>
                <div className="item"><img src="https://demo.cloudgoup.com/wp-content/uploads/2024/05/4-5.png" /></div>
                <div className="item"><img src="https://demo.cloudgoup.com/wp-content/uploads/2024/05/7-1.png" /></div>
                <div className="item"><img src="https://demo.cloudgoup.com/wp-content/uploads/2024/05/9.png" /></div>
                <div className="item"><img src="https://demo.cloudgoup.com/wp-content/uploads/2024/05/13.png" /></div>
                <div className="item"><img src="https://demo.cloudgoup.com/wp-content/uploads/2024/05/12.png" /></div>
                <div className="item"><img src="https://demo.cloudgoup.com/wp-content/uploads/2024/05/10.png" /></div>
                <div className="item"><img src="https://demo.cloudgoup.com/wp-content/uploads/2024/05/11.png" /></div>
                <div className="item"><img src="https://demo.cloudgoup.com/wp-content/uploads/2024/05/8.png" /></div>
            </div>
        </div>
    </motion.div>
  )
}

export default Section9