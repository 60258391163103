import React from 'react'
import { FaTwitter, FaInstagram, FaFacebook, FaWhatsapp } from 'react-icons/fa';

const Footer = () => {
  return (
    <div className="mt-auto container-fluid border-top border-opacity-50 bg-white">
        <div className='container-xl'>
            <footer>
                <div className="row justify-content-between py-5">
                    <div className="col-8 col-md-3 row p-0 m-0 gap-3">
                        <div>
                            <a><img src="/logo2.png" alt="Cloudgoup" className="logo light-mode-item navbar-brand-item img-fluid" /></a>
                        </div>
                        <div className='p'>
                            At Cloud Go Up, our commitment is to empower you with effortless creation of exceptional digital solutions that are efficient and of the highest quality. Our team of experts collaborates seamlessly with cutting-edge AI-powered tools to help you produce top-notch websites, mobile apps, and digital strategies that captivate your audience and drive success for your business.
                        </div>
                        <div>
                            <ul className="list-unstyled d-flex">
                                <li className="ms-3"><a className="link-dark" href="#"> <FaFacebook size={24} /></a></li>
                                <li className="ms-3"><a className="link-dark" href="#"> <FaInstagram size={24} /></a></li>
                                <li className="ms-3"><a className="link-dark" href="#"> <FaWhatsapp size={24} /></a></li>
                            </ul>
                        </div>
                    </div>

                    <div className="col-6 col-md-2 section">
                        <h5>Section</h5>
                        <ul className="nav flex-column">
                        <li className="nav-item mb-2"><a href="#" className="nav-link p-0 text-muted">Home</a></li>
                        <li className="nav-item mb-2"><a href="#" className="nav-link p-0 text-muted">Features</a></li>
                        <li className="nav-item mb-2"><a href="#" className="nav-link p-0 text-muted">Pricing</a></li>
                        <li className="nav-item mb-2"><a href="#" className="nav-link p-0 text-muted">FAQs</a></li>
                        <li className="nav-item mb-2"><a href="#" className="nav-link p-0 text-muted">About</a></li>
                        </ul>
                    </div>

                    <div className="col-6 col-md-2 section">
                        <h5>Section</h5>
                        <ul className="nav flex-column">
                        <li className="nav-item mb-2"><a href="#" className="nav-link p-0 text-muted">Home</a></li>
                        <li className="nav-item mb-2"><a href="#" className="nav-link p-0 text-muted">Features</a></li>
                        <li className="nav-item mb-2"><a href="#" className="nav-link p-0 text-muted">Pricing</a></li>
                        <li className="nav-item mb-2"><a href="#" className="nav-link p-0 text-muted">FAQs</a></li>
                        <li className="nav-item mb-2"><a href="#" className="nav-link p-0 text-muted">About</a></li>
                        </ul>
                    </div>
                </div>
                <div className="text-center py-4 border-top p">
                    © 2024 CloudgoUp, All rights reserved.
                </div>
            </footer>
        </div>
        <style>{`
        footer .logo{
        width: 120px;
        }

        footer .section h5{
        letter-spacing: -0.02em;
        color: #272657;
        font-weight: 600;
        font-size: 1.15rem;
        }
        footer .section ul li{
        color: #64607d;
        line-height: 30px;
        }
        footer .p{
        margin-bottom: 0;
        color: #64607d;
        line-height: 30px;
        }       
        `}</style>
    </div>
  )
}

export default Footer