import React from 'react'
import { MdPhonelinkSetup } from "react-icons/md";
import { TfiPanel } from "react-icons/tfi";
import { FaMotorcycle } from "react-icons/fa6";
import { FaRightLeft } from "react-icons/fa6";
import { motion } from "framer-motion";

const Section8 = () => {
    const fadeInAnimationVariants = {
        initial: {
            opacity: 1,
            y: 0
        },
        animate: {
            opacity: 1,
            y: 0,
            transition: { duration: 0.6, delay: 0.2 }
        }
    }
  return (
    <div className="container-fluid bg-white py-5 section8">
        <div className="row container-xl mx-auto text-center">
            <ul className="d-flex justify-content-center align-items-center list-unstyled gap-3 m-0 fs-5">
                <li className="d-flex align-items-center rounded-5 p-3 bg-light border"><MdPhonelinkSetup /></li>
                <li className="p">Delivery App</li>
                <li ><FaRightLeft /></li>
                <li className="d-flex align-items-center rounded-5 p-3 bg-light border"><TfiPanel /></li>
                <li className="p">Admin Panel</li>
                <li><FaRightLeft /></li>
                <li className="d-flex align-items-center rounded-5 p-3 bg-light border"><FaMotorcycle /></li>
                <li className="p">Driver App</li>
            </ul>
        </div>
        <style>{`
           .section8{
            overflow: auto;
          }
          @media screen and (max-width: 550px) {
            .section8 ul li{
              font-size: .8rem !important;
            }
          }
          .section8 .p {
            line-height: 1em;
            letter-spacing: -0.045em;
            color: #272657;
            font-weight: 600;
          }
        `}</style>
    </div>
  )
}

export default Section8