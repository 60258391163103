import React, { useEffect, useRef } from 'react';
import 'owl.carousel'; // Import Owl Carousel
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';

const Section2 = () => {

    const [t, i18n] = useTranslation("global");

    const dir = Cookies.get('direction') || 'ltr';

    const rtl = (dir == 'rtl');

    const owlCarouselRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        let initialized = false;

        const initializeCarousel = () => {
            $(owlCarouselRef.current!).owlCarousel({
                margin: 30,
                loop: true,
                autoplay: true,
                autoplayTimeout: initialized ? 3000 : 0, // Set initial autoplayTimeout to 0
                smartSpeed: 3000,
                touchDrag: false,
                mouseDrag: false,
                rtl: rtl,
                nav: false,
                dots: false,
                items: 6,
                responsive: {
                    0: {
                        items: 2
                    },
                    600: {
                        items: 2
                    },
                    1000: {
                        items: 5
                    }
                }
            });

            // Set initialized to true after first initialization
            initialized = true;

            // Update autoplayTimeout after the first rotation
            $(owlCarouselRef.current!).on('changed.owl.carousel', function(event) {
                if (!initialized) return;
                $(owlCarouselRef.current!).trigger('stop.owl.autoplay');
                $(owlCarouselRef.current!).trigger('play.owl.autoplay', [3000]);
            });
        };

        initializeCarousel();

        // Cleanup on unmount
        return () => {
            $(owlCarouselRef.current!).trigger('destroy.owl.carousel');
        };
    }, [rtl]);

    return (
        <div className="container-fluid bg-white py-5">
            <div className="row container-xl mx-auto section2">
                <div className="col-xl-2 col-md-2 mb-sm-3 text-center text-md-start mb-4 mb-md-0 left d-md-flex align-items-center">
                    <h3 className="fs-5 m-0">{t("home.section2.title")}</h3>
                </div>
                <div ref={owlCarouselRef} className="owl-carousel owl-theme col-xl-10 col-md-10">
                    <div className="item"><img src="https://demo.cloudgoup.com/wp-content/uploads/2024/05/3-1.png" /></div>
                    <div className="item"><img src="https://demo.cloudgoup.com/wp-content/uploads/2024/05/1-1.png" /></div>
                    <div className="item"><img src="https://demo.cloudgoup.com/wp-content/uploads/2024/05/2-1.png" /></div>
                    <div className="item"><img src="https://demo.cloudgoup.com/wp-content/uploads/2024/05/4-1.png" /></div>
                    <div className="item"><img src="https://demo.cloudgoup.com/wp-content/uploads/2024/05/5-1.png" /></div>
                    <div className="item"><img src="https://demo.cloudgoup.com/wp-content/uploads/2024/05/6-1.png" /></div>
                </div>
            </div>
            <style>{`
                .section2 .left h3{
                    line-height: 1em;
                    color: #272657;
                    font-weight: 600;
                }
            `}</style>
        </div>
    )
}

export default Section2