import React from 'react'
import Header from './Header'
import Footer from './Footer'

const Layout = ({ children }:any) => {
  return (
    <div className="d-flex flex-column min-vh-100 bg-light">
        <Header />
            {children}
        <Footer />
    </div>
  )
}

export default Layout